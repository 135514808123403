import ApiRequest from "./ApiRequest";
import * as SessionStorageUtils from "../utils/SessionStorageUtils";
import LocalStorageConstants from "../consts/LocalStorageConstants";

const Api = new ApiRequest(process.env.REACT_APP_KA4_API_URL);

export function getFaq() {
  return Api.get(`/ka4/v1/faq`);
}

export function login(email, password) {
  const body = {
    loginId: email,
    password,
  };
  return Api.post(`/ka4/v1/admin/user/sign-in`, null, body);
}

export function loginByToken() {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/user/token`, accessToken);
}

export function checkLeftNode(qrCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {qrCode};
  return Api.get(`/ka4/v1/admin/product/register/left-serial-overlap`, accessToken, body);
}

export function checkRightNode(qrCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {qrCode};
  return Api.get(`/ka4/v1/admin/product/register/right-serial-overlap`, accessToken, body);
}

export function checkPinCode(pinCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {pinCode};
  return Api.get(`/ka4/v1/admin/product/register/pin-code-overlap`, accessToken, body);
}

export function checkVinCode(vinCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {vinCode};
  return Api.get(`/ka4/v1/admin/product/register/vin-code-overlap`, accessToken, body);
}

export function setRegister(lhQrCode, rhQrCode, pinCode, vinCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {lhQrCode, rhQrCode, pinCode, vinCode};
  return Api.post(`/ka4/v1/admin/product/register/device`, accessToken, body);
}

export function checkQrCode(qrCode, registered) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {qrCode, registered};
  return Api.get(`/ka4/v1/admin/product/register/check-qr-code`, accessToken, body);
}

export function nodeExchange(sourceQrCode, targetQrCode) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  const body = {sourceQrCode, targetQrCode};
  return Api.patch(`/ka4/v2/admin/product/register/node-change`, accessToken, body);
}

export function getNodeList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/node/list`, accessToken, params);
}

export function putNode(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/product/node`, accessToken, params);
}

export function postNode(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/product/node`, accessToken, params);
}

export function getFirmwareList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/firmware/list`, accessToken, params);
}

export function postFirmware(params) {
  const formData  = new FormData();

  for(const name in params) {
    formData.append(name, params[name]);
  }
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/product/firmware`, accessToken, formData);
}

export function putFirmware(params) {
  const formData  = new FormData();

  for(const name in params) {
    formData.append(name, params[name]);
  }
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/product/firmware`, accessToken, formData);
}

export function getNodeDetail(id) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/node/detail?id=${id}`, accessToken);
}

export function getUserList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/user/user/list`, accessToken, params);
}

export function getUserDetail(id) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/user/user/detail?id=${id}`, accessToken);
}

export function postUser(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/user/user`, accessToken, params);
}

export function putUser(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/user/user`, accessToken, params);
}

export function getProcessTotal(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/product/processTotal/list`, accessToken, params);
}

export function getProcess1st(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/process1st/list`, accessToken, params);
}

export function getProcess2nd(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/process2nd/list`, accessToken, params);
}

export function getProcess3rd(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/process3rd/list`, accessToken, params);
}

export function getProcess4th(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/process4th/list`, accessToken, params);
}

export function getReplacementList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/product/replacement/list`, accessToken, params);
}

export function getBreakdownList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/product/breakdown/list`, accessToken, params);
}

export function getFaqList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/faq/list`, accessToken, params);
}

export function patchFaqSort(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.patch(`/ka4/v1/admin/management/faq/sort`, accessToken, params);
}

export function putFaq(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/management/faq`, accessToken, params);
}

export function postFaq(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/management/faq`, accessToken, params);
}

export function getTermsList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/terms/list`, accessToken, params);
}

export function putTerms(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/management/terms`, accessToken, params);
}

export function postTerms(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/management/terms`, accessToken, params);
}

export function getNotificationList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/notification/list`, accessToken, params);
}

export function postNotification(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/management/notification`, accessToken, params);
}

export function getAppVersionDetail() {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/app_version/detail`, accessToken);
}

export function putAppVersionDetail(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/management/app_version`, accessToken, params);
}

export function getErrorCodeDetail(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/error_code/detail`, accessToken, params);
}

export function putErrorCodeDetail(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/management/error_code`, accessToken, params);
}

export function getPresetDetail(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/management/preset/detail`, accessToken, params);
}

export function putPreset(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/management/preset`, accessToken, params);
}

export function getAdminList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/admin/user/list`, accessToken, params);
}

export function putAdmin(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.put(`/ka4/v1/admin/admin/user`, accessToken, params);
}

export function postAdmin(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.post(`/ka4/v1/admin/admin/user`, accessToken, params);
}

export function getHistoryUserList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/history/user/list`, accessToken, params);
}

export function getHistoryUserDetail(id) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v2/admin/history/user/detail?id=${id}`, accessToken);
}

export function getHistoryAppList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/history/app/list`, accessToken, params);
}

export function getHistoryAppDetail(id) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/history/app/detail?id=${id}`, accessToken);
}

export function getHistoryDeviceList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/history/device/list`, accessToken, params);
}

export function getHistoryDeviceDetail(id) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/history/device/detail?id=${id}`, accessToken);
}

export function getPinCodeRegisteredList(params) {
  const accessToken = SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken);
  return Api.get(`/ka4/v1/admin/product/pin-code-registered/list`, accessToken, params);
}
