export function setValue(key, value) {
  localStorage.setItem(key, JSON.stringify({value}));
}

export function getValue(key, defaultValue) {
  const result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(result).value;
  } else {
    return defaultValue;
  }
}

export function setList(key, array, maxLength) {
  if (maxLength) {
    setValue(key, array.slice(-maxLength));
  } else {
    setValue(key, array);
  }
}
