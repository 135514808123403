import React from 'react';
import EventTypes from "../../consts/EventTypes";
import {createEventBusHelper} from "../../utils/EventBusHelper";

const EventBusHelper = createEventBusHelper();

export default class DialogManager extends React.Component {
  static push(dialog) {
    EventBusHelper.publish(EventTypes.Dialog.Push, dialog);
  }

  static pop() {
    EventBusHelper.publish(EventTypes.Dialog.Pop);
  }

  state = {
    stack: []
  };

  constructor(props, context) {
    super(props, context);

    EventBusHelper.addListener(EventTypes.Dialog.Push, (data) => this.push(data));
    EventBusHelper.addListener(EventTypes.Dialog.Pop, () => this.pop());
  }

  componentDidUnMount() {
    EventBusHelper.removeAllListeners();
  }

  id() {
    const alpha = 'qwertyuiopasdfghjklzxcvbnm'.split('');
    let id = '';
    while (alpha.length) {
      const i = Math.floor(Math.random() * alpha.length);
      id += alpha[i];
      alpha.splice(i, 1)
    }
    return id;
  }

  push(dialog) {
    const newStack = this.state.stack.slice();
    newStack.push({id: this.id(), open: true, dialog});
    this.setState({stack: newStack});
  }

  pop() {
    let {stack} = this.state;
    let top = null;
    for (let i = stack.length - 1; 0 <= i; i--) {
      const item = stack[i];
      if (item.open === true) {
        top = item;
        break;
      }
    }
    if (!top) {
      return;
    }

    top.open = false;
    this.forceUpdate();

    setTimeout(() => {
      const newStack = stack.reduce((array, value, index) => {
        if (top.id !== value.id) {
          array.push(value);
        }
        return array;
      }, []);
      this.setState({stack: newStack});
    }, 300);
  }

  render() {
    return (
      <>
        {this.state.stack.map((value, index) => {
          const {id, open, dialog} = value;
          return React.cloneElement(dialog, {
            key: id,
            open,
            onClose: (result) => {
              this.pop();
              dialog.props.onClose(result);
            }
          });
        })}
      </>
    );
  }
}
