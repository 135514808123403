import qs from 'qs';
import isEmpty from 'lodash.isempty';

function request(method, url, accessToken, body = {}) {
  const options = {
    method: method,
    headers: {}
  };

  if (method !== 'GET') {
    if (body instanceof FormData) {
      options.body = body;
    } else {
      options.headers = {'Content-Type': 'application/json'};
      options.body = JSON.stringify(body);
    }
  }

  if (accessToken) {
    options.headers.Authorization = accessToken;
  }

  return new Promise((resolve, reject) => {
    fetch(url, options).then((res) => res.json()).then(async (res) => {
      if (!res.status) {
        resolve(res);
      } else if (res.status === 200 || res.status === 201) {
        resolve(res.data);
      } else {
        reject(res);
      }
    }).catch(async (error) => {
      if (error.json) {
        try {
          reject(await error.json());
        } catch (e) {
          reject(e);
        }
      } else {
        reject(error);
      }
    });
  });
}

class ApiRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  get = (url, accessToken, params) => {
    if (!isEmpty(params)) {
      url = `${url}?${qs.stringify(params, {indices: false})}`;
    }
    return request('GET', this.baseUrl + url, accessToken);
  };
  post = (url, accessToken, body) => {
    return request('POST', this.baseUrl + url, accessToken, body);
  };
  put = (url, accessToken, body) => {
    return request('PUT', this.baseUrl + url, accessToken, body);
  };
  patch = (url, accessToken, body) => {
    return request('PATCH', this.baseUrl + url, accessToken, body);
  };
  delete = (url, accessToken, body) => {
    return request('DELETE', this.baseUrl + url, accessToken, body);
  };
}

export default ApiRequest;
