const types = {
  App: {
    AuthToken: '',
  },
  KA4: {
    AdminPinList: '',
  },
  Settings: {
    MenuProps: ''
  }
};

(() => {
  for (let c in types) {
    for (let e in types[c]) {
      types[c][e] = `${c}.${e}`;
    }
  }
})();

export default types;
