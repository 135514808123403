import * as EventBus from '../utils/EventBus';
import * as ModelUtils from '../utils/ModelUtils';
import EventTypes from "../consts/EventTypes";

export function auth(error) {
  if (error.status === 401) {
    EventBus.publish(EventTypes.App.Logout);
    return true;
  }
  return false;
}

export function all(error) {
  let message = error.message;
  switch (error.status) {
    case 400:
      break;
    case 401:
      message = '다시 로그인해주세요.';
      EventBus.publish(EventTypes.App.Logout);
      break;
    default:
      if (ModelUtils.isLiveMode()) {
        message = '일시적인 오류가 발생하였습니다. 잠시후 다시 시도해 주세요.';
      }
      break;
  }

  alert(message);
}

export function ignore(error) {
  console.error(error);
}
