import {AmpStories, Home, People, PhoneIphone, Business, History} from "@material-ui/icons";

export default {
  '/': {
    name: '홈',
    path: '/',
    icon: Home
  },
  '/product': {
    name: '제품관리',
    path: '/product',
    icon: AmpStories
  },
  '/product/nodeList': {
    name: '노드리스트',
    path: '/product/nodeList',
    role: 1,
  },
  '/product/firmwareList': {
    name: '펌웨어 관리',
    path: '/product/firmwareList',
    role: 4,
  },
  '/product/processTotal': {
    name: '공정종합',
    path: '/product/processTotal',
  },
  '/product/process1st': {
    name: '1공정관리',
    path: '/product/process1st',
    role: 8,
  },
  '/product/process2nd': {
    name: '2공정관리',
    path: '/product/process2nd',
    role: 10,
  },
  '/product/process3rd': {
    name: '3공정관리',
    path: '/product/process3rd',
    role: 12,
  },
  '/product/pinCodeRegistered': {
    name: 'PinCode 등록이력',
    path: '/product/pinCodeRegistered',
    role: 61,
  },
  // '/product/nodeRegister': {
  //   name: '3공정_기기등록',
  //   path: '/product/nodeRegister',
  //   role: 12,
  // },
  // '/product/nodeExchange': {
  //   name: '기기교환',
  //   path: '/product/nodeExchange',
  //   role: 12,
  // },
  '/product/process4th': {
    name: 'AS관리',
    path: '/product/process4th',
    role: 14,
  },
  '/product/replacement': {
    name: '교체 관리',
    path: '/product/replacement',
    role: 16,
  },
  '/product/breakdown': {
    name: '고장 이력 관리',
    path: '/product/breakdown',
    role: 19,
  },
  '/user': {
    name: '회원관리',
    path: '/user',
    icon: People
  },
  '/user/list': {
    name: '회원정보',
    path: '/user/list',
    role: 22,
  },
  '/history': {
    name: '이력관리',
    path: '/history',
    icon: History
  },
  '/history/user': {
    name: '사용이력',
    path: '/history/user',
    role: 25,
  },
  '/history/app': {
    name: '앱로그',
    path: '/history/app',
    role: 53,
  },
  '/management': {
    name: '앱관리',
    path: '/management',
    icon: PhoneIphone
  },
  '/management/faq': {
    name: '자주묻는질문 관리',
    path: '/management/faq',
    role: 27,
  },
  '/management/terms': {
    name: '약관 관리',
    path: '/management/terms',
    role: 31,
  },
  '/management/notification': {
    name: '푸시 알림 관리',
    path: '/management/notification',
    role: 34,
  },
  '/management/appVersion': {
    name: '앱버전 관리',
    path: '/management/appVersion',
    role: 37,
  },
  '/management/errorCode': {
    name: '에러코드 관리',
    path: '/management/errorCode',
    role: 39,
  },
  // '/management/preset': {
  //   name: '초기값 관리',
  //   path: '/management/preset',
  //   role: 48,
  // },
  '/admin': {
    name: '기업아이디',
    path: '/admin',
    icon: Business
  },
  '/admin/list': {
    name: '기업 아이디 관리',
    path: '/admin/list',
    role: 42,
  },
};
