import React from 'react';
import * as SessionStorageUtils from "../utils/SessionStorageUtils";
import LocalStorageConstants from "../consts/LocalStorageConstants";
import {createEventBusHelper} from '../utils/EventBusHelper';
import EventTypes from "../consts/EventTypes";
import {useHistory} from "react-router-dom";
import LoadingView from "../components/common/LoadingView";
import * as KA4Apis from "../apis/KA4Apis";
import * as ApiErrorHandler from "../apis/ApiErrorHandler";
import DialogManager from "../components/common/DialogManager";
import {ToastContainer, Slide} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EventBusHelper = createEventBusHelper();

const AppContext = React.createContext();

const AppProvider = ({children}) => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const [authToken, setAuthToken] = React.useState(SessionStorageUtils.getValue(LocalStorageConstants.App.AuthToken));
  const [roles, setRoles] = React.useState([]);

  const setAuthTokenWrapper = (data) => {
    SessionStorageUtils.setValue(LocalStorageConstants.App.AuthToken, data);
    setAuthToken(data);
  };

  const store = {
    setLoading,
    authToken, setAuthToken: setAuthTokenWrapper,
    roles, setRoles,
  };

  React.useEffect(() => {
    if (authToken) {
      setLoading(true);
      KA4Apis.loginByToken().then((res) => {
        setAuthToken(res.accessToken);
        setRoles(res.adminRole);
      }).catch(ApiErrorHandler.all).finally(() => {
        setLoading(false);
        setLoaded(true);
      });
    } else {
      setLoaded(true);
    }

    EventBusHelper.addListener(EventTypes.App.Logout, () => {
      setAuthTokenWrapper(null);
      history.replace('/sign-in');
    });

    return () => {
      EventBusHelper.removeAllListeners();
    };
  }, []);

  return (
    <AppContext.Provider value={store}>
      {loaded && children}
      <DialogManager/>
      <LoadingView loading={loading}/>
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        autoClose={1500}
        transition={Slide}
      />
    </AppContext.Provider>
  );
};

export {
  AppProvider,
  AppContext,
};
