import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import {AmpStories, ExitToApp, Home, People, PhoneIphone} from "@material-ui/icons";
import * as EventBus from "../../utils/EventBus";
import EventTypes from "../../consts/EventTypes";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import * as LocalStorageUtils from "../../utils/LocalStorageUtils";
import LocalStorageConstants from "../../consts/LocalStorageConstants";
import DialogView from "../common/DialogView";
import {AppContext} from "../../contexts/AppContext";
import RouterConstants from "../../consts/RouterConstants";
import {isMobile} from 'react-device-detect';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 1, 3, 2),
    justifyContent: 'space-between',
  },
  breadcrumb: {
    flexGrow: 1,
    color: 'lightgray'
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  children: {
    position: 'absolute',
    left: theme.spacing(3),
    right: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const menuItems = [
  RouterConstants['/'],
  {
    ...RouterConstants['/product'],
    items: [
      RouterConstants['/product/nodeList'],
      RouterConstants['/product/firmwareList'],
      RouterConstants['/product/processTotal'],
      RouterConstants['/product/process1st'],
      RouterConstants['/product/process2nd'],
      RouterConstants['/product/process3rd'],
      RouterConstants['/product/pinCodeRegistered'],
      // RouterConstants['/product/nodeRegister'],
      // RouterConstants['/product/nodeExchange'],
      RouterConstants['/product/process4th'],
      RouterConstants['/product/replacement'],
      RouterConstants['/product/breakdown'],
    ],
  },
  {
    ...RouterConstants['/user'],
    items: [
      RouterConstants['/user/list'],
    ],
  },
  {
    ...RouterConstants['/history'],
    items: [
      RouterConstants['/history/user'],
      RouterConstants['/history/app'],
    ],
  },
  {
    ...RouterConstants['/management'],
    items: [
      RouterConstants['/management/faq'],
      RouterConstants['/management/terms'],
      RouterConstants['/management/notification'],
      RouterConstants['/management/appVersion'],
      RouterConstants['/management/errorCode'],
      // RouterConstants['/management/preset'],
    ],
  },
  {
    ...RouterConstants['/admin'],
    items: [
      RouterConstants['/admin/list'],
    ],
  },
];

const breadCrumbNameMap = {};
const createBreadCrumbNameMap = (items) => {
  items.map(({name, path, items}) => {
    breadCrumbNameMap[path] = name;
    if (items) {
      createBreadCrumbNameMap(items);
    }
  });
};
createBreadCrumbNameMap(menuItems);

const LinkRouter = (props) => <Link {...props} component={RouterLink}/>;

const ListGroup = ({roles, name, icon: ListGroupIcon, items, onClickItem}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(LocalStorageUtils.getValue(LocalStorageConstants.Settings.MenuProps + name, false));
  const onClick = () => {
    setOpen(!open);
    LocalStorageUtils.setValue(LocalStorageConstants.Settings.MenuProps + name, !open);
  };
  const listItems = items.reduce((array, value, index) => {
    const {role, name} = value;
    if (!role || roles.includes(role)) {
      array.push(
        <ListItem key={index} button className={classes.nested} onClick={() => {
          if (onClickItem) onClickItem(index);
        }}>
          <ListItemText secondary={name}/>
        </ListItem>
      );
    }
    return array;
  }, []);

  if (listItems.length > 0) {
    return (
      <List>
        <ListItem button onClick={onClick}>
          <ListItemIcon>
            <ListGroupIcon/>
          </ListItemIcon>
          <ListItemText primary={name}/>
          {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {listItems}
          </List>
        </Collapse>
      </List>
    );
  } else {
    return null;
  }
};

export default ({children}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const {roles} = React.useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [logoutDialogVisible, setLogoutDialogVisible] = React.useState(false);

  const pathNames = location.pathname.split('/').filter((x) => x);

  const handleOnClick = (path) => {
    history.replace(path);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon/>
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <LinkRouter style={{display: 'flex', color: pathNames.length === 0 ? 'white' : 'lightgray'}} to="/">
              <Home className={classes.breadcrumbIcon}/>
            </LinkRouter>
            {!isMobile && pathNames.map((value, index) => {
              const last = index === pathNames.length - 1;
              const to = `/${pathNames.slice(0, index + 1).join('/')}`;

              return last ? (
                <Typography style={{color: 'white'}} key={to}>
                  {breadCrumbNameMap[to]}
                </Typography>
              ) : (
                <LinkRouter style={{color: 'lightgray'}} to={to} key={to}>
                  {breadCrumbNameMap[to]}
                </LinkRouter>
              );
            })}
          </Breadcrumbs>
          <Button color="inherit" startIcon={<ExitToApp/>} onClick={() => setLogoutDialogVisible(true)}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div role="presentation">
          <div className={classes.drawerHeader}>
            <Typography variant="h5">
              Tune!t
            </Typography>
          </div>
          <Divider/>
          {menuItems.map((value, index) => {
            const {name, icon: Icon, path, items} = value;
            if (items) {
              return (
                <ListGroup
                  roles={roles}
                  key={`drawer-item-${index}`}
                  name={name}
                  icon={Icon}
                  items={items}
                  onClickItem={(index) => handleOnClick(items[index].path)}/>
              );
            } else {
              return (
                <List key={`drawer-item-${index}`}>
                  <ListItem button onClick={() => handleOnClick(path)}>
                    <ListItemIcon>
                      <Icon/>
                    </ListItemIcon>
                    <ListItemText primary={name}/>
                  </ListItem>
                </List>
              );
            }
          })}
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.drawerHeader}/>
        <div className={classes.children}>
          {children}
        </div>
      </main>
      <DialogView
        open={logoutDialogVisible}
        message={'로그아웃 하시겠습니까?'}
        left={'취소'}
        right={'확인'}
        onClose={(result) => {
          setLogoutDialogVisible(false);
          if (result === '확인') {
            EventBus.publish(EventTypes.App.Logout);
          }
        }}
      />
    </div>
  );
}
