export function setValue(key, value) {
  sessionStorage.setItem(key, JSON.stringify({value}));
}

export function getValue(key, defaultValue) {
  const result = sessionStorage.getItem(key);
  if (result) {
    return JSON.parse(result).value;
  } else {
    return defaultValue;
  }
}
