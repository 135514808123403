import React from 'react';
import moment from "moment";
import 'moment/locale/ko'
import * as Sentry from '@sentry/react';
import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./router/AppRouter";
import {AppProvider} from "./contexts/AppContext";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {},
});

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiFormLabel-root': {
      fontSize: '0.9rem',
    },
    '.MuiInputBase-root': {
      fontSize: '0.9rem',
    },
    '.MuiTypography-body1': {
      fontSize: '0.9rem',
    },
  },
})(() => null);

moment.locale('ko');

Sentry.init({
  dsn: "https://75efa869e61b492285ea582cafa50565@o362618.ingest.sentry.io/5338698",
  environment: process.env.NODE_ENV,
});

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalCss/>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <AppProvider>
            <AppRouter/>
          </AppProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}
