import React from "react";
import {Redirect, Route} from "react-router-dom";
import {AppContext} from "../contexts/AppContext";

export default (props) => {
  const {role, name, layout: Layout, component: Component, ...rest} = props;
  const {authToken, roles} = React.useContext(AppContext);

  if (!authToken) {
    return <Redirect to={{pathname: '/sign-in', state: {from: props.location}}}/>;
  }
  if (role && !roles.includes(role)) {
    return <Redirect to={{pathname: '/'}}/>;
  }
  if (!Layout) {
    return <Route {...rest} component={Component}/>;
  }
  return <Route
    {...rest}
    render={matchProps => (
      <Layout><Component name={name} {...matchProps}/></Layout>
    )}
  />;
}
