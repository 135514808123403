import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";
import MainLayout from "../components/layouts/MainLayout";
import {createEventBusHelper} from "../utils/EventBusHelper";
import EventTypes from "../consts/EventTypes";
import LoadingView from "../components/common/LoadingView";
import RouterConstants from "../consts/RouterConstants";

const EventBusHelper = createEventBusHelper();

// etc.
const Home = lazy(() => import(/* webpackChunkName: "Main" */'../components/home/Home'));
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */'../components/signIn/SignIn'));
const Faq = lazy(() => import(/* webpackChunkName: "Faq" */'../components/webview/Faq'));

// product
const NodeList = lazy(() => import(/* webpackChunkName: "NodeList" */'../components/product/NodeList'));
const FirmwareList = lazy(() => import(/* webpackChunkName: "FirmwareList" */'../components/product/FirmwareList'));
const ProcessTotal = lazy(() => import(/* webpackChunkName: "ProcessTotal" */'../components/product/ProcessTotal'));
const Process1st = lazy(() => import(/* webpackChunkName: "Process1st" */'../components/product/Process1st'));
const Process2nd = lazy(() => import(/* webpackChunkName: "Process2nd" */'../components/product/Process2nd'));
const Process3rd = lazy(() => import(/* webpackChunkName: "Process3rd" */'../components/product/Process3rd'));
const Process4th = lazy(() => import(/* webpackChunkName: "Process4th" */'../components/product/Process4th'));
const PinCodeRegisteredList = lazy(() => import(/* webpackChunkName: "PinCodeRegisteredList" */'../components/product/PinCodeRegisteredList'));
// const NodeRegister = lazy(() => import(/* webpackChunkName: "NodeRegister" */'../components/product/NodeRegister'));
// const NodeExchange = lazy(() => import(/* webpackChunkName: "NodeExchange" */'../components/product/NodeExchange'));
const ReplacementList = lazy(() => import(/* webpackChunkName: "ReplacementList" */'../components/product/ReplacementList'));
const BreakdownList = lazy(() => import(/* webpackChunkName: "BreakdownList" */'../components/product/BreakdownList'));

// user
const UserList = lazy(() => import(/* webpackChunkName: "UserList" */'../components/user/UserList'));

// history
const HistoryUserList = lazy(() => import(/* webpackChunkName: "HistoryUserList" */'../components/history/UserList'));
const HistoryAppList = lazy(() => import(/* webpackChunkName: "HistoryAppList" */'../components/history/AppList'));

// management
const FaqList = lazy(() => import(/* webpackChunkName: "FaqList" */'../components/management/FaqList'));
const TermsList = lazy(() => import(/* webpackChunkName: "TermsList" */'../components/management/TermsList'));
const NotificationList = lazy(() => import(/* webpackChunkName: "NotificationList" */'../components/management/NotificationList'));
const AppVersionManagement = lazy(() => import(/* webpackChunkName: "AppVersionManagement" */'../components/management/AppVersionManagement'));
const ErrorCodeList = lazy(() => import(/* webpackChunkName: "ErrorCodeList" */'../components/management/ErrorCodeList'));
// const PresetManagement = lazy(() => import(/* webpackChunkName: "PresetManagement" */'../components/management/PresetManagement'));

// admin
const AdminList = lazy(() => import(/* webpackChunkName: "AdminList" */'../components/admin/AdminList'));

export default () => {
  const history = useHistory();

  React.useEffect(() => {
    // native 처리
    EventBusHelper.addListener(EventTypes.Native.Back, () => history.goBack());
    return () => {
      EventBusHelper.removeAllListeners();
    }
  }, []);

  return (
    <Suspense fallback={<LoadingView loading={true}/>}>
      <Switch>
        <PrivateRoute exact path='/' layout={MainLayout} component={Home}/>
        <Route path='/sign-in' component={SignIn}/>
        <PrivateRoute {...RouterConstants['/product/nodeList']} layout={MainLayout} component={NodeList}/>
        <PrivateRoute {...RouterConstants['/product/firmwareList']} layout={MainLayout} component={FirmwareList}/>
        <PrivateRoute {...RouterConstants['/product/processTotal']} layout={MainLayout} component={ProcessTotal}/>
        <PrivateRoute {...RouterConstants['/product/process1st']} layout={MainLayout} component={Process1st}/>
        <PrivateRoute {...RouterConstants['/product/process2nd']} layout={MainLayout} component={Process2nd}/>
        <PrivateRoute {...RouterConstants['/product/process3rd']} layout={MainLayout} component={Process3rd}/>
        <PrivateRoute {...RouterConstants['/product/pinCodeRegistered']} layout={MainLayout} component={PinCodeRegisteredList}/>
        {/*<PrivateRoute {...RouterConstants['/product/nodeRegister']} layout={MainLayout} component={NodeRegister}/>*/}
        {/*<PrivateRoute {...RouterConstants['/product/nodeExchange']} layout={MainLayout} component={NodeExchange}/>*/}
        <PrivateRoute {...RouterConstants['/product/process4th']} layout={MainLayout} component={Process4th}/>
        <PrivateRoute {...RouterConstants['/product/replacement']} layout={MainLayout} component={ReplacementList}/>
        <PrivateRoute {...RouterConstants['/product/breakdown']} layout={MainLayout} component={BreakdownList}/>
        <Redirect from='/product' to='/product/nodeList'/>

        <PrivateRoute {...RouterConstants['/user/list']} layout={MainLayout} component={UserList}/>

        <PrivateRoute {...RouterConstants['/history/user']} layout={MainLayout} component={HistoryUserList}/>
        <PrivateRoute {...RouterConstants['/history/app']} layout={MainLayout} component={HistoryAppList}/>

        <PrivateRoute {...RouterConstants['/management/faq']} layout={MainLayout} component={FaqList}/>
        <PrivateRoute {...RouterConstants['/management/terms']} layout={MainLayout} component={TermsList}/>
        <PrivateRoute {...RouterConstants['/management/notification']} layout={MainLayout} component={NotificationList}/>
        <PrivateRoute {...RouterConstants['/management/appVersion']} layout={MainLayout} component={AppVersionManagement}/>
        <PrivateRoute {...RouterConstants['/management/errorCode']} layout={MainLayout} component={ErrorCodeList}/>
        {/*<PrivateRoute {...RouterConstants['/management/preset']} layout={MainLayout} component={PresetManagement}/>*/}
        <Redirect from='/management' to='/management/faq'/>

        <PrivateRoute {...RouterConstants['/admin/list']} layout={MainLayout} component={AdminList}/>
        <Route path='/webview/faq' component={Faq}/>
        <Redirect to='/'/>
      </Switch>
    </Suspense>
  );
}
