import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default ({open, title, message, left, right, onClose, children}) => (
  <Dialog
    fullWidth
    open={open}
    scroll={'paper'}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      {message && <DialogContentText>
        {message}
      </DialogContentText>}
      {children}
    </DialogContent>
    <DialogActions>
      {left && <Button autoFocus onClick={() => onClose(left)} color="primary">{left}</Button>}
      {right && <Button autoFocus onClick={() => onClose(right)} color="primary">{right}</Button>}
    </DialogActions>
  </Dialog>
);
